<script>
import ParallaxBlock from '@/components/common/ParallaxBlock';
import LinkListItem from '@/components/common/LinkListItem';

export default {
  name: 'WhoIAmLinks',
  components: {
    ParallaxBlock,
    LinkListItem
  },
  props: {
    scrollY: {
      type: Number,
      default: 0
    },
    parallaxRatio: {
      type: Number,
      default: 0
    }
  }
};
</script>

<template lang="pug">
  ParallaxBlock.p-whoiam-links(
    :scrollY = 'scrollY'
    :parallaxRatio = 'parallaxRatio'
    )
    .p-whoiam-links__items
      LinkListItem.p-whoiam-links__item(
        href = 'https://instagram.com/wonedesignofficial'
        target = '_blank'
        )
        |Instagram
      LinkListItem.p-whoiam-links__item(
        href = 'https://wa.me/905308335364'
        target = '_blank'
        )
        |WhatsApp
    .p-whoiam-links__items
      LinkListItem.p-whoiam-links__item(
        href = 'mhttps://www.google.com/maps/dir/37.8863616,32.505856/Musalla+Bağları+Mahallesi,+Kule+Caddesi,+Selçuklu+Kulesi+Sitesi,+No:2,+Kat:20,+No:31+Selçuklu+%2F+Konya+42060/@37.888936,32.4955651,17.76z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14d0856a8c4253ff:0xa3f4734ad6a0dcbd!2m2!1d32.4943138!2d37.8885825'
        )
        |Location on Maps
</template>

<style lang="scss">
.p-whoiam-links {
  @include l-more-than-mobile {
    width: math.div(500, 1360) * 100%;
    position: absolute;
    top: 270px;
    left: math.div(180, 1360) * 100%;
  }
  @include l-mobile {
    margin-bottom: 50px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }

  // Transition
  transition-property: opacity, transform;
  .view-enter &,
  .view-asc-enter & {
    opacity: 0;
  }
  .view-enter &,
  .view-asc-leave-to & {
    transform: translate3d(0, 100px, 0);
  }
  .view-asc-enter &,
  .view-leave-to & {
    transform: translate3d(0, -100px, 0);
  }
  .view-enter-to &,
  .view-asc-enter-to & {
    opacity: 1;
    transition-duration: 1s;
    transition-delay: 1.3s;
    transition-timing-function: $easeOutQuad;
  }
  .view-leave-to &,
  .view-asc-leave-to & {
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.1s;
    transition-timing-function: $easeInQuad;
  }
}
</style>
